console.log(`%c
        .-.__.-.__.-.__.-._.-.
       (     Meow meow meow!  )
        "-"( where's the damn )"
            "-"( \`truth\`? )
                '-..-"-..-'           _________________
                       O          ___( Hey listen to   )_
                ,_    o         _( your heart and define )__
          ____   \\"\\___,-'7    ( the \`meaning\` first. Meow. )
  ____..-"_.-"|   )     (/      """""""((""""""""""""""""""""
 |   |====    |   a_ /@  E              \\\\
 |## |====    | =: T ::= )      __       ))
 |42 |====    |   \\/\\   <,     {__\\      Y
 |___|====____|    7"\\_// \\       \\\\  |\\__,-7
     _/____\\_     /( (@)   \\      )) _}; .  {
    '.,____,.'-.,( \\_____ ) \\    //   \\^ = _/
     ._ _        | (((__ ~   ) _//    /&~~")     ,
       Y       = |      '-,_    / T-cc_  // }   ((
       | __,..--"'          "-,/_ | |  cc7_(     ))
                                 "+-,_ |  |"|_,,;/
                                      "-, | |--"
`, 'font-family:monospace;');

const truth = `We are one with the universe, meow`;
window.getTruth = () => console.log(meaning === 42 && truth);

console.log(`getTruth()`);
getTruth();
